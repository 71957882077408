import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Heading from 'common/src/components/Heading';
import Fade from 'react-reveal/Fade';
import Text from 'common/src/components/Text';
import Container from 'common/src/components/UI/Container';
import Carousel from './carousel';
import { SectionHeader, CircleLoader } from '../interior.style';
import SectionWrapper, { CarouselWrapper } from './testimonial.style';

const Testimonial = () => {
  const data = useStaticQuery(graphql`
    query {
      interiorJson {
        testimonialData {
          title
          slogan
          description
          features {
            id
            color
            icon {
              publicURL
            }
            title
            comment
          }
        }
      }
    }
  `);
  const {
    title,
    slogan,
    features,
    description,
  } = data.interiorJson.testimonialData;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <SectionWrapper id="process">
      <Container width="1360px">
        <SectionHeader>
          <Fade up>
            <Heading as="h5" content={title} />
            <Heading content={slogan} />
          </Fade>
        </SectionHeader>
        <Fade up>
          <Text content={description} />
        </Fade>
        <Fade up>
          <CarouselWrapper>
            {loading ? (
              <Carousel data={features} />
            ) : (
              <CircleLoader className="alt">
                <div className="circle"></div>
                <div className="circle"></div>
              </CircleLoader>
            )}
          </CarouselWrapper>
        </Fade>
      </Container>
    </SectionWrapper>
  );
};

export default Testimonial;
