import React, { Fragment, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'common/src/components/Image';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import { SectionHeader, CircleLoader } from '../interior.style';
import Input from 'common/src/components/Input';
import SectionWrapper, {
  TeamCard,
  Card,
  TextWrapper,
  CarouselWrapper,
  CardContainer,
  CarouselLogo,
} from './team.style';

const Team = () => {
  const data = useStaticQuery(graphql`
    query {
      interiorJson {
        footerData {
          logo {
            publicURL
          }
        }
        teamData {
          title
          slogan
          checkmark {
            publicURL
          }
          members {
            avatar {
              publicURL
            }
            id
            name
            date
            designation
            address
          }
        }
      }
    }
  `);
  const { title, slogan, members, checkmark } = data.interiorJson.teamData;
  const { logo } = data.interiorJson.footerData;

  const [loading, setLoading] = useState(false);

  const glideOptions = {
    type: 'carousel',
    perView: 3,
    gap: 50,
    perTouch: 1,
    breakpoints: {
      1200: {
        perView: 2,
        gap: 30,
      },
      767: {
        perView: 2,
        gap: 10,
      },
      480: {
        perView: 1,
      },
    },
  };

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <SectionWrapper id="stores">
      <Container width="1360px">
        <Fade bottom>
          <SectionHeader>
            <Heading as="h5" content={title} />
            <Heading content={slogan} />
          </SectionHeader>
        </Fade>

        <Fade bottom delay={30}>
          <CarouselWrapper>
            {loading ? (
              <GlideCarousel
                carouselSelector="team_carousel"
                options={glideOptions}
                nextButton={<span className="next_arrow" />}
                prevButton={<span className="prev_arrow" />}
              >
                <Fragment>
                  {members.map(member => (
                    <GlideSlide key={`team_key${member.id}`}>
                      <TeamCard className="team_card">
                        <CardContainer>
                          <Card>
                            <div class="cardTop">
                              <div>
                                <Text
                                  className="cardHeading"
                                  content={member.name}
                                />
                              </div>
                              <div>
                                <img src={checkmark.publicURL} />
                              </div>
                            </div>
                            <div class="cardBody">
                              <div>
                                <p class="cardDate">
                                  Certified on &nbsp;<span>{member.date}</span>
                                </p>
                              </div>
                              <div>
                                <CarouselLogo>
                                  <img src={member.avatar.publicURL} />
                                </CarouselLogo>
                              </div>
                            </div>
                            <div class="cardFooter">
                              <a
                                target="_blank"
                                href={
                                  'http://maps.google.com/?q=' +
                                  encodeURI(member.address)
                                }
                              >
                                📍{member.designation}
                              </a>
                            </div>
                          </Card>
                        </CardContainer>
                      </TeamCard>
                    </GlideSlide>
                  ))}
                </Fragment>
              </GlideCarousel>
            ) : (
              <CircleLoader className="alt">
                <div className="circle"></div>
                <div className="circle"></div>
              </CircleLoader>
            )}
          </CarouselWrapper>
        </Fade>
      </Container>
    </SectionWrapper>
  );
};

export default Team;
