import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { openModal, closeModal } from '@redq/reuse-modal';
import { Icon } from 'react-icons-kit';
import { androidClose } from 'react-icons-kit/ionicons/androidClose';
import NavbarWrapper from 'common/src/components/Navbar';
import Drawer from 'common/src/components/Drawer';
import Button from 'common/src/components/Button';
import Logo from 'common/src/components/UIElements/Logo';
import HamburgMenu from 'common/src/components/HamburgMenu';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';
import { DrawerContext } from 'common/src/contexts/DrawerContext';
import { Container } from './navbar.style';
import SearchPanel from '../SearchPanel';
import LoginModal from '../LoginModal';
import Copyright from '../Copyright';
import * as typeformEmbed from '@typeform/embed';

import logo from 'common/src/assets/image/interior/logo.svg';

const Navbar = ({ navbarStyle, logoStyle }) => {
  const data = useStaticQuery(graphql`
    query {
      interiorJson {
        menuData {
          path
          label
          offset
        }
        socialProfile {
          id
          icon
          link
        }
      }
    }
  `);

  const { state, dispatch } = useContext(DrawerContext);

  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container>
        <Logo
          href="/"
          logoSrc={logo}
          title="CovidSafetyReport"
          logoStyle={logoStyle}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            isMaterial
            className="myNavButton"
            variant="extendedFab"
            title="Get Certified"
            aria-label="search button"
            onClick={() => {
              typeformEmbed.makePopup(
                'https://covidsafetyreport.typeform.com/to/ZERv2R',
                {
                  mode: 'drawer_right',
                  autoOpen: true,
                  autoClose: 3,
                  hideScrollbars: true,
                  onSubmit: function() {
                    console.log('Typeform successfully submitted');
                  },
                }
              );
            }}
          />

          <Drawer
            width="420px"
            placement="right"
            drawerHandler={<HamburgMenu />}
            open={state.isOpen}
            toggleHandler={toggleHandler}
          >
            <button
              type="button"
              className={state.isOpen ? 'active' : ''}
              onClick={toggleHandler}
              aria-label="drawer toggle button"
            >
              <Icon icon={androidClose} />
            </button>
            <ScrollSpyMenu
              menuItems={data.interiorJson.menuData}
              drawerClose={true}
              offset={-100}
            />
            <Copyright data={data.interiorJson.socialProfile} />
          </Drawer>
        </div>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
  },
  logoStyle: {
    width: 'auto',
    height: '50px',
  },
};

export default Navbar;
