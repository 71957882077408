import React, { Fragment, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Icon } from 'react-icons-kit';
import { iosEmailOutline } from 'react-icons-kit/ionicons/iosEmailOutline';
import Image from 'common/src/components/Image';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
import Button from 'common/src/components/Button';
import Input from 'common/src/components/Input';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import { CircleLoader } from '../interior.style';
import BannerWrapper, {
  Container,
  ContentArea,
  HighlightedText,
  FormWrapper,
  ButtonGroup,
  CarouselArea,
} from './banner.style';

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      interiorJson {
        bannerData {
          title
          text
          carousel {
            id
            title
            thumb_url {
              publicURL
            }
            link
          }
        }
      }
    }
  `);

  const { title, text, carousel } = data.interiorJson.bannerData;

  const glideOptions = {
    type: 'carousel',
    perView: 3,
    gap: 20,
    breakpoints: {
      1200: {
        perView: 2,
      },
      667: {
        perView: 2,
      },
      480: {
        perView: 1,
      },
    },
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
  }, []);

  const [state, setState] = useState({ email: '', valid: '' });

  return (
    <BannerWrapper>
      <Container>
        <ContentArea>
          <Fade bottom delay={30}>
            <Heading as="h1" content={title} />
            <Text content={text} />

            <Fade right>
              <AnchorLink
                offset={70}
                href="#checklist"
                className="learn__more-btn"
              >
                <span className="btn_text">Learn More</span>
                <span className="next_arrow"></span>
              </AnchorLink>
            </Fade>
          </Fade>
        </ContentArea>
        {/* End of content section */}
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
